import {
    type FieldSchemaType,
    fieldSchema,
    validateAfterDate,
    validateBeforeDate,
    validateDate,
    validateEmail,
    validateNumber,
    validateRequired,
    validateRequiredIf,
    validateString,
} from '@i2e/forms';
import * as z from 'zod';

import i18n from '@/lib/i18n';

const optionsSchema = z.array(
    z.object({
        id: z.number(),
        name: z.string().nullable(),
        startDateTime: z.string().nullable(),
        endDateTime: z.string().nullable(),
        isShowday: z.number().nullable(),
        items: z.array(
            z
                .object({
                    id: z.number(),
                    type: z.string(),
                    name: z.string().nullable(),
                    category: z
                        .object({
                            id: z.number(),
                            name: z.string(),
                        })
                        .nullable(),
                    limit: z.number(),
                    assigned: z.number().default(0),
                })
                .refine(
                    (value) => {
                        return (
                            value.limit < 0 ||
                            (value.assigned >= 0 && value.assigned <= value.limit)
                        );
                    },
                    {
                        message: 'Assigned must be a number between 0 and limit',
                        path: ['assigned'],
                    },
                ),
        ),
    }),
);

type OptionsType = z.infer<typeof optionsSchema>;

const runRefinement = ({
    fieldIdentifier,
    fields,
    ctx,
    lng = 'en',
}: {
    fieldIdentifier: string;
    fields?: FieldSchemaType[] | undefined;
    ctx: z.RefinementCtx;
    lng?: string;
}) => {
    fields?.forEach((field, index) => {
        // Check if the field is required
        validateRequired({
            fieldIdentifier,
            field,
            index,
            ctx,
            message: i18n.t('form.required', { ns: 'errors', lng }),
        });

        // Check if the field is required if another field has a specific value
        validateRequiredIf({
            fieldIdentifier,
            fields,
            field,
            index,
            ctx,
            message: i18n.t('form.required', { ns: 'errors', lng }),
        });

        // Check if the field is an email
        validateEmail({
            fieldIdentifier,
            field,
            index,
            ctx,
            message: i18n.t('form.invalid.email', { ns: 'errors', lng }),
        });

        // Check if the field is a number
        validateNumber({
            fieldIdentifier,
            field,
            index,
            ctx,
            message: i18n.t('form.invalid.number', { ns: 'errors', lng }),
        });

        // Check if the field is a valid date
        validateDate({
            fieldIdentifier,
            field,
            index,
            ctx,
            message: i18n.t('form.invalid.date', { ns: 'errors', lng }),
        });

        // Check if the field is a string
        validateString({
            fieldIdentifier,
            field,
            index,
            ctx,
            message: i18n.t('form.invalid.string', { ns: 'errors', lng }),
        });

        // Check if the date is before the specified date
        validateBeforeDate({
            fieldIdentifier,
            field,
            index,
            ctx,
            message: i18n.t('form.invalid.date.before', { ns: 'errors', lng }),
        });

        // Check if the date is after the specified date
        validateAfterDate({
            fieldIdentifier,
            field,
            index,
            ctx,
            message: i18n.t('form.invalid.date.after', { ns: 'errors', lng }),
        });
    });
};

/**
 * This is used for the form schema
 * It is used to validate the form data
 */
const formSchema = (lng = 'en') =>
    z
        .object({
            firstName: z
                .string()
                .min(1, {
                    message: i18n.t('external.request.form.first.name.required', {
                        ns: 'external',
                        lng,
                    }),
                })
                .min(2),
            lastName: z
                .string()
                .min(1, {
                    message: i18n.t('external.request.form.last.name.required', {
                        ns: 'external',
                        lng,
                    }),
                })
                .min(2),
            email: z
                .string()
                .min(1, {
                    message: i18n.t('external.request.form.email.required', {
                        ns: 'external',
                        lng,
                    }),
                })
                .email({
                    message: i18n.t('external.request.form.email.invalid', { ns: 'external', lng }),
                }),
            telephone: z
                .string()
                .min(1, {
                    message: i18n.t('external.request.form.telephone.required', {
                        ns: 'external',
                        lng,
                    }),
                })
                .regex(
                    /^\s*(?:\+?(?<countryCode>\d{1,3}))?(?:[-. (]*(?<areaCode>\d{3})[-. )]*)?(?:(?<prefix>\d{3})[-. ]*(?<lineNumber>\d{2,4})(?:[-.x ]*(?<extension>\d+))?)\s*$/gm,
                    {
                        message: i18n.t('external.request.form.telephone.invalid', {
                            ns: 'external',
                            lng,
                        }),
                    },
                ),
            options: optionsSchema,
            userMetas: z.array(fieldSchema).optional(),
            eventAttendeeMetas: z.array(fieldSchema).optional(),
        })
        .superRefine((data, ctx) => {
            runRefinement({
                fieldIdentifier: 'userMetas',
                fields: data.userMetas,
                ctx,
                lng,
            });

            runRefinement({
                fieldIdentifier: 'eventAttendeeMetas',
                fields: data.eventAttendeeMetas,
                ctx,
                lng,
            });

            // At least one option must be assigned
            const assignedOptions = data.options.filter((option) => {
                return option.items.some((item) => item.assigned > 0);
            });

            if (assignedOptions.length === 0) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: i18n.t('external.request.form.options.required', {
                        ns: 'external',
                        lng,
                    }),
                    path: ['customOptionsError'],
                });
            }
        });

type FormSchemaType = z.infer<ReturnType<typeof formSchema>>;

export {
    fieldSchema,
    type FieldSchemaType,
    formSchema,
    type FormSchemaType,
    optionsSchema,
    type OptionsType,
};
