import { hasMessage } from '@i2e/components';
import { useState, useTransition } from 'react';

interface PostProps<T, K> {
    payload: K;
    onSuccess?: (responseData: T) => Promise<void> | void;
    onError?: (error: Error) => void;
    onEnd?: () => void;
}

// Define the structure for the hook's return type, allowing for generic response data (T)
// and settings type (K), with a default of the Settings interface.
export interface UsePostResult<T, K> {
    post: (props: PostProps<T, K>) => Promise<T | undefined>;
    isPending: boolean;
    isLoading: boolean;
    error: Error | null;
    success: boolean;
    data: T | null;
}

interface UsePostProps {
    method?: 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    url: string;
    headers?: Record<string, string>;
    messages?: {
        server?: string;
        client?: string;
    };
}

// The custom hook, parametrized with response data type T and payload type K.
function usePost<T, K>({
    method = 'POST',
    url,
    headers,
    messages: {
        server = 'An error occurred on the server',
        client = 'An error occurred on the client',
    } = {},
}: UsePostProps): UsePostResult<T, K> {
    // State management hooks for handling the request state.
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const [success, setSuccess] = useState(false);
    const [data, setData] = useState<T | null>(null);
    // UseTransition for managing non-urgent updates.
    const [isPending, startTransition] = useTransition();

    // The function for posting data, with success, error, and end callbacks.
    const post = async ({ payload, onSuccess, onError, onEnd }: PostProps<T, K>) => {
        startTransition(() => {
            setIsLoading(true);
            setError(null);
            setSuccess(false);
            setData(null);
        });

        try {
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    ...headers,
                },
                body: JSON.stringify(payload),
            });

            // Parse the response data as type T.
            const responseData = (await response.json()) as T;

            if (!response.ok) {
                // If the response is not ok, check if it has a message and throw an error.
                if (hasMessage(responseData)) {
                    throw new Error(String(responseData.message));
                } else {
                    // Handle case where response does not contain a message.
                    throw new Error(server);
                }
            }

            startTransition(() => {
                setData(responseData);
                setSuccess(true);
            });

            await onSuccess?.(responseData);

            return responseData;
        } catch (err) {
            const errorInstance = err instanceof Error ? err : new Error(client);
            startTransition(() => {
                setError(errorInstance);
                onError?.(errorInstance);
            });
            // throw errorInstance;
            /**
             * @jannie
             * because the error is thrown in the catch block, it is not necessary to throw it again here.
             * Otherwise you will have to wrap the post function in a try-catch block to catch the error.
             */
        } finally {
            onEnd?.();
            startTransition(() => {
                setIsLoading(false);
            });
        }
    };

    return { post, isPending, isLoading, error, success, data };
}

export { usePost };
