'use client';

import { ImageFallback } from '@i2e/components';
import { CancelCircleSymbol } from '@in2event/icons';

import { TypographyH1, TypographyMuted } from '@/components/typography';
import { useDocumentTitle } from '@/hooks/use-document-title';
import { useTranslation } from '@/lib/i18n/client';

import { RequestForm } from './form';
import FormLayout from './form/components/form/layout';
import FormLocked from './form-locked';
import useProcessedData from './hooks/use-processed-data';

interface RequestPageProps {
    eventUserListId: string;
}

const RequestPage = ({ eventUserListId }: RequestPageProps) => {
    const { t } = useTranslation(['external']);

    const {
        userMetas,
        eventAttendeeMetas,
        options,
        language,
        formData,
        isLoading,
        isProcessing,
        error,
    } = useProcessedData(eventUserListId);

    // Set document title
    useDocumentTitle(`${String(formData?.event.name)} - ${String(formData?.reference)} - In2event`);

    if (isLoading) {
        return (
            <div className="flex h-screen flex-col items-center justify-center space-y-4">
                <TypographyMuted>{t('external.request.form.loading')}</TypographyMuted>
            </div>
        );
    }

    if (!formData || error) {
        return (
            <div className="flex h-screen flex-col items-center justify-center space-y-4">
                <CancelCircleSymbol className="size-14 fill-destructive-600" />
                <TypographyH1 className="tracking-5">
                    {t('external.request.form.not.found')}
                </TypographyH1>
            </div>
        );
    }

    if (isProcessing) {
        return (
            <div className="flex h-screen flex-col items-center justify-center space-y-4">
                <TypographyMuted>{t('external.request.form.processing')}</TypographyMuted>
            </div>
        );
    }

    const { backgroundColor, contentBackgroundColor, textColor } = formData.event.branding.colors;

    return (
        <div
            className="fixed inset-0 flex h-screen flex-col overflow-y-auto bg-base-100"
            style={{
                ...(backgroundColor && {
                    backgroundColor,
                }),
                ...(textColor && {
                    color: textColor,
                }),
            }}
        >
            <div className="relative z-10 min-h-[196px] w-full shrink-0 overflow-hidden bg-base-100 md:min-h-[294px]">
                <div className="absolute bottom-0 z-10 h-[60px] w-full bg-gradient-to-b from-neutral-1 to-neutral-20" />

                <ImageFallback
                    alt={`Banner for ${formData.event.name}`}
                    className="object-cover object-center"
                    draggable={false}
                    fallbackSrc="/images/external/default-banner.png"
                    fill
                    priority
                    src={formData.event.branding.headerImage?.url ?? ''}
                />
            </div>

            {formData.locked ? (
                <FormLocked backgroundColor={contentBackgroundColor} />
            ) : (
                <FormLayout backgroundColor={contentBackgroundColor}>
                    <RequestForm
                        promotor={formData.event.promotor}
                        defaultValues={{
                            firstName: '',
                            lastName: '',
                            email: '',
                            telephone: '',
                            userMetas,
                            eventAttendeeMetas,
                            options,
                        }}
                        event={formData.event}
                        eventUserListId={eventUserListId}
                        introductionText={formData.introductionText}
                        title={formData.reference}
                        submitText={formData.submitText}
                        language={language}
                    />
                </FormLayout>
            )}
        </div>
    );
};

export default RequestPage;
