import { ReactNode } from 'react';

import { ExternalFooter } from '@/components/i2e-footer';

interface FormLayoutProps {
    backgroundColor: string | null;
    children: ReactNode;
}

const FormLayout = ({ children, backgroundColor }: FormLayoutProps) => {
    return (
        <div className="flex flex-col items-center md:absolute md:inset-x-6 md:bottom-0 md:top-[108px] lg:inset-x-0">
            <div
                className="z-20 mb-10 w-full max-w-2xl border border-neutral-1900 bg-white px-4 py-9 md:rounded-2xl md:p-9 lg:min-w-2xl"
                style={{
                    ...(backgroundColor && {
                        backgroundColor,
                    }),
                }}
            >
                {children}
            </div>
            <ExternalFooter className="pt-0" />
        </div>
    );
};

export default FormLayout;
