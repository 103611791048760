'use client';

import { cn } from '@i2e/components';
import { PoweredByLogo } from '@in2event/icons';

interface ExternalFooterProps {
    className?: string;
}

export const ExternalFooter = ({ className }: ExternalFooterProps) => (
    <div className={cn('flex flex-col items-center justify-center gap-3 pb-16 pt-32', className)}>
        <PoweredByLogo />
    </div>
);
