import { Button } from '@i2e/components';
import { AddIcon, RemoveIcon } from '@in2event/icons';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

interface OptionButtonProps extends ComponentPropsWithoutRef<typeof Button> {
    method?: 'increment' | 'decrement';
}

const OptionButton = forwardRef<ElementRef<typeof Button>, Omit<OptionButtonProps, 'children'>>(
    (
        { method = 'increment', type = 'button', size = 'icon', variant = 'subtle', ...props },
        forwardedRef,
    ) => {
        return (
            <Button type={type} size={size} variant={variant} {...props} ref={forwardedRef}>
                {method === 'increment' ? (
                    <AddIcon className="size-5 text-neutral-60" />
                ) : (
                    <RemoveIcon className="size-5 text-neutral-60" />
                )}
            </Button>
        );
    },
);
OptionButton.displayName = 'OptionButton';

export { OptionButton };
