import { AllInclusiveIcon } from '@in2event/icons';

interface OptionDateProps {
    isEvent?: boolean;
    day: string;
    month: string;
}

export const OptionDate = ({ isEvent = false, day, month }: OptionDateProps) => {
    return (
        <div className="flex h-12 w-11 shrink-0 flex-col items-center justify-center rounded bg-base-200 text-neutral-60">
            <div className="text-lg font-bold leading-[22px]">
                {isEvent ? <AllInclusiveIcon className="size-[22px]" /> : day}
            </div>
            <div className="text-3xs font-medium uppercase leading-3">
                {isEvent ? 'event' : month}
            </div>
        </div>
    );
};
