import { ImageFallback } from '@i2e/components';
import { LocationIcon } from '@in2event/icons';

interface FormHeaderProps {
    logo: string;
    eventName: string;
    promotor: string | null;
    location: string | null;
    title: string;
}

export const FormHeader = ({ title, logo, eventName, promotor, location }: FormHeaderProps) => {
    return (
        <section className="flex flex-col gap-6 md:flex-row">
            <div className="relative aspect-square size-28 shrink-0 overflow-hidden rounded-xl bg-white shadow-event-logo max-md:mt-[-92px]">
                <ImageFallback
                    alt="event-logo"
                    className="object-contain object-center"
                    draggable={false}
                    fallbackSrc="/images/logo.png"
                    fill
                    src={`${logo}/c/224x224`}
                />
            </div>

            <div className="flex h-full min-h-[94px] grow flex-col justify-center gap-y-[18px] py-2.5 md:min-h-[112px]">
                <span className="text-2xs font-bold uppercase leading-3 tracking-6 text-neutral-60">
                    {title}
                </span>
                <h1 className="text-2xl font-bold leading-7 -tracking-2 text-neutral-250">
                    {eventName}
                </h1>
                <ul className="flex flex-col items-start gap-2 text-sm font-medium leading-4 text-neutral-60 md:flex-row">
                    {promotor ? <li>{promotor}</li> : null}
                    {location ? (
                        <>
                            {promotor ? <li className="max-md:hidden">•</li> : null}
                            <li className="inline-flex gap-x-base">
                                <LocationIcon className="size-4 text-neutral-750" /> {location}
                            </li>
                        </>
                    ) : null}
                </ul>
            </div>
        </section>
    );
};
