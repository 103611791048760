import { cn } from '@i2e/components';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

export const TypographyH1 = forwardRef<HTMLHeadingElement, HTMLAttributes<HTMLHeadingElement>>(
    ({ className, ...props }, ref) => {
        return (
            <h1
                className={cn(
                    'text-2xl font-bold leading-7 tracking-[-0.015em] text-neutral-250',
                    className,
                )}
                ref={ref}
                {...props}
            />
        );
    },
);
TypographyH1.displayName = 'TypographyH1';
