import { Heading } from '@i2e/components';
import { LockIcon } from '@in2event/icons';

import { ExternalFooter } from '@/components/i2e-footer';
import { TypographyP } from '@/components/typography';

interface FormLockedProps {
    backgroundColor: string | null;
}

const FormLocked = ({ backgroundColor }: FormLockedProps) => {
    return (
        <div className="flex flex-col items-center justify-between md:absolute md:inset-x-6 md:bottom-0 md:top-[108px] lg:inset-x-0">
            <div
                className="z-20 max-w-2xl border border-neutral-1800 bg-white px-4 py-9 md:rounded-2xl md:px-16 md:py-[90px] lg:min-w-2xl"
                style={{
                    ...(backgroundColor && {
                        backgroundColor,
                    }),
                }}
            >
                <div className="mx-auto flex size-full max-w-[512px] flex-col items-center justify-center text-center">
                    <LockIcon className="mb-3 size-20 text-neutral-1250" />
                    <Heading variant="large" className="mb-4 tracking-[-1%]">
                        Form locked
                    </Heading>
                    <TypographyP>
                        You cannot edit the form because it has been locked. Contact the event
                        organizer for support.
                    </TypographyP>
                </div>
            </div>
            <ExternalFooter />
        </div>
    );
};

export default FormLocked;
