import type { FieldSchemaType } from '@i2e/forms';
import { getFieldValueBaseOnType } from '@i2e/forms';
import { useEffect, useState, useTransition } from 'react';

import { useTranslation } from '@/lib/i18n/client';
import { parseExternalRequestFormData } from '@/schemas/external-request';
import type { OptionsType } from '@/schemas/external-request-form';
import { useGetExternalRequest } from '@/services/external/request-form/use-get-request';

interface ProcessedData {
    userMetas: FieldSchemaType[];
    eventAttendeeMetas: FieldSchemaType[];
    options: OptionsType;
    language: string;
}

// Define your custom hook
const useProcessedData = (eventUserListId: string) => {
    const { t } = useTranslation();

    const { formData, isLoading, error } = useGetExternalRequest({
        eventUserListId,
        config: {
            revalidateOnFocus: true,
            errorRetryCount: 2,
            revalidateIfStale: false,
        },
    });

    const success = formData && parseExternalRequestFormData(formData);

    const [processedData, setProcessedData] = useState<ProcessedData>({
        userMetas: [],
        eventAttendeeMetas: [],
        options: [],
        language: 'en',
    });

    // Using useTransition to handle loading state transitions
    const [isProcessing, startTransition] = useTransition();

    useEffect(() => {
        // Synchronously update state based on data availability & form validity
        startTransition(() => {
            if (!isLoading && formData) {
                // Get language
                const getLanguage = (lang: string) => {
                    switch (lang) {
                        case 'english':
                            return 'en';
                        case 'dutch':
                            return 'nl';
                        case 'german':
                            return 'de';
                        default:
                            return 'en';
                    }
                };

                const language = getLanguage(formData.language);

                // Process userMetas
                const userMetas: FieldSchemaType[] = formData.userMetas.map(
                    ({ label, ...field }) => ({
                        ...field,
                        label: t(label, { lng: language }),
                        value: field.type ? getFieldValueBaseOnType(field.type) : '',
                    }),
                );

                // Process eventAttendeeMetas
                const eventAttendeeMetas: FieldSchemaType[] = formData.eventAttendeeMetas.map(
                    ({ key, label, ...field }) => ({
                        ...field,
                        label: t(label, { lng: language }),
                        key: key.toString(),
                        value: field.type ? getFieldValueBaseOnType(field.type) : '',
                    }),
                );

                // Process options
                const options: OptionsType = Object.values(formData.dates).map(
                    ({ items, ...date }) => ({
                        ...date,
                        items: items.map((item) => ({ ...item, assigned: 0 })),
                    }),
                );

                // Set primary color
                const primaryColor = formData.event.branding.colors.accentColor ?? '#0057FF';
                // Set primary color as CSS variable
                document.documentElement.style.setProperty('--primary-600', primaryColor);

                // Set processed data
                setProcessedData({
                    userMetas,
                    eventAttendeeMetas,
                    options,
                    language,
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps -- we don't want t
    }, [formData, isLoading]);

    return {
        ...processedData,
        formData,
        isLoading,
        isProcessing,
        error,
        formIsValid: success,
    };
};

export default useProcessedData;
