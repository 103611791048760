import { InputText, cn } from '@i2e/components';
import { FormControl, FormField, FormItem } from '@i2e/forms';
import { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';

import { OptionButton } from '../options';

export interface OptionFieldProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
    limit: number;
}

const OptionField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    limit,
    ...props
}: OptionFieldProps<TFieldValues, TName>) => {
    return (
        <FormField
            {...props}
            render={({ field: { onChange, ...field } }) => {
                // Handle decrement
                const handleDecrement = () => {
                    // Assigned value is a string so we need to convert it to a number and then decrement it
                    // This cannot go below 0
                    const assigned = Math.max(Number(field.value) - 1, 0);
                    // Only update the value in the form if it's a positive value
                    if (assigned >= 0) {
                        // Update the value in the form, converting it back to a string
                        onChange(assigned);
                    }
                };

                // Handle increment
                const handleIncrement = () => {
                    // Assigned value is a string so we need to convert it to a number and then increment it
                    // This cannot go above the limit
                    const assigned = Math.min(Number(field.value) + 1, limit);

                    // Only update the value in the form if it's a positive value
                    if (assigned >= 0) {
                        // Update the value in the form, converting it back to a string
                        onChange(assigned);
                    }
                };

                const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = parseInt(e.target.value, 10);

                    onChange(value >= 0 ? value : 0);
                };

                return (
                    <div className="flex w-32 gap-1.5">
                        <OptionButton onClick={handleDecrement} method="decrement" />
                        <FormItem>
                            <FormControl>
                                <InputText
                                    {...field}
                                    onChange={handleOnChange}
                                    className={cn(
                                        'w-12 bg-white text-center font-medium text-neutral-250',
                                        field.value === 0 && 'text-neutral-50',
                                    )}
                                    variant="compact"
                                />
                            </FormControl>
                        </FormItem>
                        <OptionButton onClick={handleIncrement} />
                    </div>
                );
            }}
            rules={{
                required: true,
            }}
        />
    );
};

export default OptionField;
