import { cn } from '@i2e/components';
import type { HtmlHTMLAttributes } from 'react';
import { forwardRef } from 'react';

export const TypographyOverline = forwardRef<HTMLSpanElement, HtmlHTMLAttributes<HTMLSpanElement>>(
    ({ className, ...props }, ref) => {
        return (
            <span
                {...props}
                ref={ref}
                className={cn('text-4xs font-bold uppercase tracking-6 text-neutral-50', className)}
            />
        );
    },
);

TypographyOverline.displayName = 'TypographyOverline';
