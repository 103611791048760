import { cn } from '@i2e/components';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

export const TypographyMuted = forwardRef<HTMLElement, HTMLAttributes<HTMLElement>>(
    ({ className, ...props }, ref) => {
        return (
            <small
                ref={ref}
                className={cn(
                    'mb-2 text-[11px] font-bold uppercase leading-3 tracking-[0.0275em] text-neutral-200/50',
                    className,
                )}
                {...props}
            />
        );
    },
);
TypographyMuted.displayName = 'TypographyMuted';
