import useSWR, { SWRConfiguration } from 'swr';

import { fetcher } from '@/lib/api/fetcher';
import { generateXAccessToken } from '@/lib/auth-utils';
import { ExternalRequestFormDataResponse } from '@/schemas/external-request';

interface Params {
    eventUserListId: string;
    config?: SWRConfiguration;
}

export const createSWRKey = (eventUserListId: string) =>
    `/api/base/requests/request/${eventUserListId}`;

export function useGetExternalRequest({ eventUserListId, config }: Params) {
    const key = `/api/base/requests/request/${eventUserListId}`;

    const options: RequestInit = {
        headers: {
            'X-Access-Token': generateXAccessToken(eventUserListId),
        },
    };

    const { data, ...props } = useSWR<ExternalRequestFormDataResponse, ErrorConstructor>(
        key,
        (url: string) => fetcher<ExternalRequestFormDataResponse>(url, options),
        config,
    );

    return {
        formData: data?.data ?? null,
        ...props,
    };
}
