import { isValid, parse } from 'date-fns';

/**
 *
 * @param date - date string
 * @returns object with month and day
 */
export const getMonthAndDate = (date: string | null) => {
    if (!date) {
        return { month: '', day: '' };
    }

    const parsedDate = parse(date.split('T')[0] ?? '', 'yyyy-MM-dd', new Date());
    const isValidDate = isValid(parsedDate);

    if (!isValidDate) {
        return { month: '', day: '' };
    }

    const month = parsedDate.toLocaleString('default', { month: 'short' });
    const day = parsedDate.toLocaleString('default', { day: '2-digit' });

    return { month, day };
};
