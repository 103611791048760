// eslint-disable-next-line unicorn/prefer-node-protocol -- this is a client side code
import { createHash } from 'crypto';
import type { IncomingMessage } from 'node:http';

import { dropRight, join, reverse, trim } from 'lodash';
import type { NextApiRequestCookies } from 'next/dist/server/api-utils';

import { getEnv } from '../../env';

import { isProduction } from './constants';

export function generateHash(string: string) {
    return createHash('sha256').update(string).digest('hex');
}

export const getCurrentUrl = (
    req: IncomingMessage & {
        cookies: NextApiRequestCookies;
    },
) => (isProduction ? `https://${req.headers.host ?? ''}` : (process.env.REFERER_DEV ?? ''));

export const generateXAccessToken = (str: string) => {
    const { API_SECRET } = getEnv();
    return generateHash(str + API_SECRET || '');
};

// https://nextjs.org/docs/pages/api-reference/next-config-js/runtime-configuration
// In a Next.js application, the process.env variables are primarily intended to be used on the server-side
// and are not directly available on the client-side by default.
// This is a security measure to ensure that sensitive information
export const generateUrlHashClient = (url: string): string => {
    const { PAGE_SECRET } = getEnv();

    const segments = url.split('/');
    const hashString = trim(join(reverse(segments), ''));

    return generateHash(hashString + PAGE_SECRET || '');
};

export const generateUrlHashServer = ({ url, drop = 1 }: { url: string; drop?: number }) => {
    const { PAGE_SECRET } = getEnv();

    const resolvedUrl = url.split('?')[0] ?? '';

    const segments = resolvedUrl.split('/');
    const hashString = trim(join(reverse(dropRight(segments, drop)), ''));

    const hash: string = generateHash(hashString + PAGE_SECRET || '');

    return hash;
};
