/**
 * Converts a hex color string to an RGB object.
 * @param hexColor A string representing a hex color.
 * @returns An object with red, green, and blue properties.
 */
export function hexToRgb(hexColor: string): { red: number; green: number; blue: number } | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
    if (!result?.[1] || !result?.[2] || !result?.[3]) return null;
    return {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
    };
}

/**
 * Calculates the appropriate text color (light or dark) based on the luminance of the background color.
 * @param backgroundColor A string representing the hex color of the background.
 * @param lightColor A string representing the light color, default is "#FFFFFF".
 * @param darkColor A string representing the dark color, default is "#000000".
 * @returns A string representing the hex color for the text.
 */
export function calculateTxtColor(
    backgroundColor: string,
    lightColor: string = '#FFFFFF',
    darkColor: string = '#000000',
): string {
    const rgb = hexToRgb(backgroundColor);
    if (!rgb) {
        throw new Error('Invalid background color');
    }
    // Calculate luminance of the background color.
    const luminance = 0.299 * rgb.red + 0.587 * rgb.green + 0.114 * rgb.blue;
    return luminance > 186 ? darkColor : lightColor;
}
