import { generateXAccessToken } from '@/lib/auth-utils';
import { PostBody } from '@/schemas/external-request';
import { usePost } from '@/services/use-post';

export const usePostRequest = ({ eventUserListId }: { eventUserListId: string }) => {
    return usePost<unknown, PostBody>({
        url: `/api/base/requests/request/${eventUserListId}`,
        headers: {
            'X-Access-Token': generateXAccessToken(eventUserListId),
        },
    });
};
