import { z } from 'zod';

import { Sentry } from '@/lib/sentry';

import { fieldSchema } from './external-request-form';

const ImageSchema = z.object({
    id: z.number().nullable(),
    url: z.string().nullable(),
});

const ColorsSchema = z.object({
    backgroundColor: z.string().nullable(),
    contentBackgroundColor: z.string().nullable(),
    textColor: z.string().nullable().optional(),
    accentColor: z.string().nullable(),
    accentTextColor: z.string().nullable().optional(),
});

const LocationSchema = z.object({
    id: z.number(),
    name: z.string(),
    address: z.string(),
    zipcode: z.string(),
    city: z.string(),
});

const BrandingSchema = z.object({
    logo: ImageSchema,
    headerImage: ImageSchema.nullable(),
    colors: ColorsSchema,
});

const EventSchema = z.object({
    id: z.number(),
    name: z.string(),
    branding: BrandingSchema,
    location: LocationSchema.nullable(),
    promotor: z.string().nullable(),
});

type Event = z.infer<typeof EventSchema>;

const CompanySchema = z.object({
    name: z.string().nullable(),
});

type Company = z.infer<typeof CompanySchema>;

const AttendeeTypeSchema = z.object({
    id: z.number(),
    name: z.string(),
});

const EventItemSchema = z.object({
    id: z.number(),
    type: z.string(),
    name: z.string().nullable(),
    category: z
        .object({
            id: z.number(),
            name: z.string(),
        })
        .nullable(),
    limit: z.number(),
});
type EventItem = z.infer<typeof EventItemSchema>;

const EventDateSchema = z.object({
    id: z.number(),
    name: z.string().nullable(),
    startDateTime: z.string().nullable(),
    endDateTime: z.string().nullable(),
    isShowday: z.number().nullable(),
    items: z.array(EventItemSchema),
});

const ExternalRequestFormDataSchema = z.object({
    id: z.number(),
    reference: z.string(),
    introductionText: z.string(),
    submitText: z.string(),
    event: EventSchema,
    attendeeType: AttendeeTypeSchema,
    userMetas: z.array(fieldSchema),
    eventAttendeeMetas: z.array(
        fieldSchema.omit({ key: true }).extend({
            key: z.number(),
        }),
    ),
    dates: z.array(EventDateSchema),
    language: z.string(),
    locked: z.boolean(),
});

type ExternalRequestFormData = z.infer<typeof ExternalRequestFormDataSchema>;

const RoleSchema = z.object({
    eventDateId: z.number(),
    eventAccessZoneRoleId: z.number(),
});

type Role = z.infer<typeof RoleSchema>;

const ItemSchema = z.object({
    eventAccreditationId: z.number(),
    eventDateId: z.number(),
    amount: z.number(),
});

type Item = z.infer<typeof ItemSchema>;

const PostBodySchema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    telephone: z.string(),
    userMetas: z.array(
        z.object({
            key: z.string(),
            value: z.any(),
        }),
    ),
    eventAttendeeMetas: z.array(
        z.object({
            metaKey: z.string(),
            metaValue: z.any(),
        }),
    ),
    roles: z.array(RoleSchema),
    items: z.array(ItemSchema),
});
type PostBody = z.infer<typeof PostBodySchema>;

const ExternalRequestFormDataResponseSchema = z.object({
    data: ExternalRequestFormDataSchema,
    success: z.boolean(),
    message: z.string().nullable(),
});

type ExternalRequestFormDataResponse = z.infer<typeof ExternalRequestFormDataResponseSchema>;

export const parseExternalRequestFormData = (data: unknown) => {
    const parsed = ExternalRequestFormDataSchema.safeParse(data);
    if (!parsed.success) {
        Sentry.captureException(parsed.error, {
            tags: {
                context: `failed to parse external request form data`,
            },
        });
    }

    return parsed.success;
};

export {
    ExternalRequestFormDataSchema,
    type ExternalRequestFormData,
    ExternalRequestFormDataResponseSchema,
    type ExternalRequestFormDataResponse,
    PostBodySchema,
    type PostBody,
    RoleSchema,
    type Role,
    ItemSchema,
    type Item,
    EventSchema,
    type Event,
    CompanySchema,
    type Company,
    EventItemSchema,
    type EventItem,
};
