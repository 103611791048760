import { zodResolver } from '@hookform/resolvers/zod';
import {
    Button,
    FormError,
    Heading,
    InputText,
    Loading,
    Separator,
    calculateTxtColor,
    cn,
    useWindowSize,
} from '@i2e/components';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    FormSwitch,
} from '@i2e/forms';
import { CheckCircleSymbol } from '@in2event/icons';
import { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import type { SubmitErrorHandler, SubmitHandler } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { toast } from 'sonner';

import { TypographyH1, TypographyP } from '@/components/typography';
import { useTranslation } from '@/lib/i18n/client';
import { Event, Item, PostBody, Role } from '@/schemas/external-request';
import { type FormSchemaType, formSchema } from '@/schemas/external-request-form';
import { usePostRequest } from '@/services/external/request-form/use-post-request';

import { FormContainer, FormFooter, FormHeader, FormTitle } from './components/form';
import OptionField from './components/form/option-field';
import {
    OptionContainer,
    OptionDate,
    OptionDescription,
    OptionItem,
    OptionItemContainer,
    OptionTitle,
} from './components/options';
import { getMonthAndDate } from './utils/get-month-and-date';

interface RequestFormProps {
    eventUserListId: string;
    title: string;
    introductionText: string;
    submitText: string;
    promotor: string | null;
    language: string;
    event: Event;
    defaultValues?: Partial<FormSchemaType>;
}

export const RequestForm = ({
    eventUserListId,
    title,
    introductionText,
    submitText,
    promotor,
    language,
    event,
    defaultValues,
}: RequestFormProps) => {
    const { t } = useTranslation(['external'], { lng: language });

    const [successful, setSuccessful] = useState(false);
    const [error, setError] = useState<Record<string, string>>({});

    const [width] = useWindowSize();
    const breakpoint = width && width < 959;

    const { post } = usePostRequest({
        eventUserListId,
    });

    const form = useForm<FormSchemaType>({
        mode: 'onBlur',
        resolver: zodResolver(formSchema(language)),
        defaultValues: defaultValues ?? {
            firstName: '',
            lastName: '',
            telephone: '',
            email: '',
            options: [],
            userMetas: [],
            eventAttendeeMetas: [],
        },
        shouldUnregister: false,
    });

    const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
        // Reset the custom errors
        setError({});

        try {
            const roles: Role[] = [];
            const items: Item[] = [];

            data.options.forEach((option) => {
                option.items.forEach((item) => {
                    if (item.type === 'item' && item.assigned > 0) {
                        items.push({
                            eventAccreditationId: item.id,
                            eventDateId: option.id,
                            amount: item.assigned,
                        });
                    } else if (item.type === 'role' && item.assigned > 0) {
                        roles.push({
                            eventDateId: option.id,
                            eventAccessZoneRoleId: item.id,
                        });
                    }
                });
            });

            const requestBody: PostBody = {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                telephone: data.telephone,
                eventAttendeeMetas:
                    data.eventAttendeeMetas
                        ?.filter((meta) =>
                            Array.isArray(meta.value)
                                ? meta.value.length > 0
                                : meta.value.trim().length > 0,
                        )
                        .map((meta) => ({
                            metaKey: String(meta.key),
                            metaValue: meta.value,
                        })) ?? [],
                userMetas:
                    data.userMetas
                        ?.filter((meta) =>
                            Array.isArray(meta.value)
                                ? meta.value.length > 0
                                : meta.value.trim().length > 0,
                        )
                        .map((meta) => ({
                            key: meta.key,
                            value: meta.value,
                        })) ?? [],
                roles,
                items,
            };

            // Post the request
            await post({
                payload: requestBody,
                onSuccess: () => {
                    // Set the successful state to true
                    setSuccessful(true);

                    // Mutate the form state to reset the form
                    // This mutate causes a refresh of the page
                    // await mutate(createSWRKey(eventUserListId));
                },
                onError: () => {
                    toast.error(t('external.request.form.submit.error'));
                },
            });
        } catch (error) {
            toast.error(t('external.request.form.submit.error'));
        }
    };

    const onError: SubmitErrorHandler<FormSchemaType> = (errors) => {
        // Reset the custom errors
        setError({});

        if ('customOptionsError' in errors) {
            const customOptionsError = errors.customOptionsError as { message?: string };
            if (customOptionsError.message) {
                setError({
                    path: 'options',
                    message: customOptionsError.message,
                });
            }

            return;
        }

        toast.error(t('external.request.form.validation.error'));
    };

    // Get the user meta fields from the form
    const { fields: userMetaFields } = useFieldArray({
        control: form.control,
        name: 'userMetas',
    });

    // Get the options from the form
    const { fields: options } = useFieldArray({
        control: form.control,
        name: 'options',
    });

    // Get the event attendee meta fields from the form
    const { fields: eventAttendeeMetaFields } = useFieldArray({
        control: form.control,
        name: 'eventAttendeeMetas',
    });

    const { accentColor, accentTextColor } = event.branding.colors;

    const buttonTextColor = accentColor ? calculateTxtColor(accentColor) : undefined;

    // console.log('successful render', successful);

    if (successful) {
        return (
            <div className="flex size-full min-w-sm max-w-2xl flex-col items-center justify-center space-y-6 py-10 text-center">
                <CheckCircleSymbol className="size-20 fill-affirmative-600" />
                <TypographyH1>{t('external.request.form.submit.success')}</TypographyH1>
                {submitText ? (
                    <TypographyP dangerouslySetInnerHTML={{ __html: submitText }} />
                ) : null}
            </div>
        );
    }

    // Create event location
    // If the address and city both is available, then show the address and city with a comma
    // If the address is available and city is not available, then show the address
    // If the address is not available and city is available, then show the city
    // If the address and city both are not available, then show nothing

    const getEventLocation = () => {
        if (event.location?.address && event.location.city) {
            return `${event.location.address}, ${event.location.city}`;
        } else if (event.location?.address && !event.location.city) {
            return event.location.address;
        } else if (!event.location?.address && event.location?.city) {
            return event.location.city;
        }
        return '';
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit, onError)}>
                <FormHeader
                    promotor={promotor}
                    eventName={event.name}
                    location={getEventLocation()}
                    logo={event.branding.logo.url ?? '/images/logo.png'}
                    title={title}
                />

                <section className="my-9">
                    <TypographyP
                        className={cn(
                            `[&_a]:font-medium [&_a]:text-blue-600 [&_a]:underline`,
                            `[&_ol]:list-decimal [&_ol]:pl-6`,
                            `[&_ul]:list-disc [&_ul]:pl-6`,
                        )}
                        dangerouslySetInnerHTML={{ __html: introductionText }}
                    />
                </section>

                <FormContainer>
                    <Heading variant="title-3">
                        {t('external.request.form.contact.details')}
                    </Heading>

                    <div className="flex flex-col items-stretch gap-x-2 gap-y-5 md:flex-row">
                        <FormField
                            control={form.control}
                            name="firstName"
                            render={({ field }) => (
                                <FormItem className="md:w-1/2">
                                    <FormLabel>{t('external.request.form.first.name')}</FormLabel>
                                    <FormControl>
                                        <InputText
                                            {...field}
                                            placeholder={t('external.request.form.first.name')}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                            rules={{
                                required: true,
                            }}
                        />

                        <FormField
                            control={form.control}
                            name="lastName"
                            render={({ field }) => (
                                <FormItem className="md:w-1/2">
                                    <FormLabel>{t('external.request.form.last.name')}</FormLabel>
                                    <FormControl>
                                        <InputText
                                            {...field}
                                            placeholder={t('external.request.form.last.name')}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                    </div>

                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>{t('external.request.form.email')}</FormLabel>
                                <FormControl>
                                    <InputText
                                        {...field}
                                        placeholder={t('external.request.form.email')}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                        rules={{
                            required: true,
                        }}
                    />

                    <FormField
                        control={form.control}
                        name="telephone"
                        render={({ field }) => (
                            <FormItem className="md:w-1/2 md:max-w-[280px]">
                                <FormLabel>{t('external.request.form.telephone')}</FormLabel>
                                <FormControl>
                                    <InputText
                                        {...field}
                                        placeholder={t('external.request.form.telephone')}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                        rules={{
                            required: true,
                        }}
                    />
                </FormContainer>

                <div className="h-5" />

                <section className="space-y-5">
                    <FormSwitch
                        fieldIdentifier="userMetas"
                        fields={userMetaFields}
                        api={{
                            uploader: '/api/base',
                        }}
                        messages={{
                            uploader: {
                                uploadMessage: (
                                    <Trans
                                        t={t}
                                        i18nKey="external.request.form.uploader.message"
                                        components={{
                                            highlighted: <b className="text-primary-600" />,
                                        }}
                                    />
                                ),
                                uploadError: t('external.request.form.uploader.error'),
                                noFileSelected: t('external.request.form.uploader.no.file'),
                            },
                        }}
                    />
                </section>

                {eventAttendeeMetaFields.length > 0 && <Separator className="my-9" />}

                {eventAttendeeMetaFields.length > 0 && (
                    <FormContainer>
                        <Heading variant="title-3">
                            {t('external.request.form.additional.details')}
                        </Heading>

                        <section className="space-y-5">
                            <FormSwitch
                                fieldIdentifier="eventAttendeeMetas"
                                fields={eventAttendeeMetaFields}
                            />
                        </section>
                    </FormContainer>
                )}

                <Separator className="my-9" />

                <FormContainer>
                    <FormTitle>{t('external.request.form.choose.from.options')}</FormTitle>

                    {options.map((option, x) => {
                        const { month, day } = getMonthAndDate(option.startDateTime);

                        return (
                            <OptionContainer key={option.id}>
                                <OptionDate
                                    isEvent={!option.startDateTime}
                                    day={day}
                                    month={month}
                                />
                                <div className="grow">
                                    <OptionItemContainer>
                                        {option.items.map((item, y) => {
                                            // If the limit is more than 50, we don't want to show the text
                                            const limitText =
                                                item.limit > 50
                                                    ? ''
                                                    : t('external.request.form.limit', {
                                                          limit: item.limit,
                                                      });

                                            // Title of the option
                                            // If the category is not available, and if name is available, then show the name
                                            // If the category is available, and if name is available, then show the category name and the name with a hyphen
                                            // If the category is available, and if name is not available, then show the category name
                                            const title = item.category
                                                ? `${item.category.name} ${item.name ? ` - ${item.name}` : ''}`
                                                : (item.name ?? '');

                                            return (
                                                <OptionItem key={item.id}>
                                                    <div className="flex w-2/3 flex-col space-y-1">
                                                        <OptionTitle>{title}</OptionTitle>
                                                        <OptionDescription>
                                                            {item.limit <= 0
                                                                ? t(
                                                                      'external.request.form.limit.not.available',
                                                                  )
                                                                : limitText}
                                                        </OptionDescription>
                                                    </div>

                                                    {item.limit > 0 ? (
                                                        <OptionField
                                                            control={form.control}
                                                            name={`options.${x}.items.${y}.assigned`}
                                                            limit={item.limit}
                                                        />
                                                    ) : null}
                                                </OptionItem>
                                            );
                                        })}
                                    </OptionItemContainer>
                                </div>
                            </OptionContainer>
                        );
                    })}

                    {error.path === 'options' ? (
                        <FormError message={error.message} className="mt-5" />
                    ) : null}
                </FormContainer>

                <FormFooter>
                    <Button
                        style={{
                            ...(accentColor && { backgroundColor: accentColor }),
                            color: accentTextColor ?? buttonTextColor,
                        }}
                        type="submit"
                        variant="primary"
                        size={breakpoint ? 'lg' : 'md'}
                        disabled={form.formState.isSubmitting}
                    >
                        {form.formState.isSubmitting ? <Loading className="mr-2 size-4" /> : null}
                        <span>{t('external.request.form.submit.button')}</span>
                    </Button>
                </FormFooter>
            </form>
        </Form>
    );
};
