import { cn } from '@i2e/components';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

export const TypographyP = forwardRef<HTMLHeadingElement, HTMLAttributes<HTMLHeadingElement>>(
    ({ className, ...props }, ref) => {
        return (
            <h3
                ref={ref}
                className={cn('text-sm font-normal text-neutral-250', className)}
                {...props}
            />
        );
    },
);
TypographyP.displayName = 'TypographyP';
